import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'emotion-theming';
import * as PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';
import theme from '../../theme';
import Helmet from './Helmet';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  body {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-family: Cabin, sans-serif;
    overflow-x: hidden;
    //background: #131d2a;
    background: #0e151c;
    color: #D8DEE9
  }

  a {
    color: #edaf35;
    text-decoration: none;
    border-bottom: 1px solid #edaf35;
    transition: color 0.5s;

    &:hover {
      color: #51b8d2;
      border-bottom: 1px solid #51b8d2;
    }
  }
`;

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {/* ThemeProvider is used to apply a theme to the Rebass components */}
    <ThemeProvider theme={theme}>
      <Helmet />
      {children}
    </ThemeProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
