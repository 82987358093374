import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Helmet = () => (
  <StaticQuery
    query={graphql`
      query HelmetQuery {
        contentfulAbout {
          name
          description
          profile {
            favicon16: resize(width: 16) {
              src
            }
            favicon32: resize(width: 32) {
              src
            }
            bigIcon: resize(width: 192) {
              src
            }
            appleIcon: resize(width: 180) {
              src
            }
          }
        }
      }
    `}
    render={(data) => {
      const { name, description, profile } = data.contentfulAbout;
      const title = `${name}`;

      return (
        <ReactHelmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <html lang="en" />

          <link rel="shortcut icon" href={`https:${profile.favicon32.src}`} />
          <meta name="image" content={`https:${profile.favicon32.src}`} />

          <meta itemProp="name" content={title} />
          <meta itemProp="description" content={description} />
          <meta itemProp="image" content={`https:${profile.favicon32.src}`} />

          <meta name="og:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="og:image" content={`https:${profile.bigIcon.src}`} />
          <meta name="og:site_name" content={title} />
          <meta name="og:locale" content="en_US" />
          <meta name="og:type" content="website" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={`https:${profile.bigIcon.src}`} />
          <meta name="twitter:image:src" content={`https:${profile.bigIcon.src}`} />

          <link rel="apple-touch-icon" sizes="180x180" href={`https:${profile.appleIcon.src}`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`https:${profile.favicon32.src}`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`https:${profile.favicon16.src}`} />

          {/*<link*/}
          {/*  href="https://fonts.googleapis.com/css2?family=Cabin&display=swap"*/}
          {/*  rel="stylesheet"*/}
          {/*  crossOrigin*/}
          {/*/>*/}
        </ReactHelmet>
      );
    }}
  />
);

export default Helmet;
