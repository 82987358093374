module.exports = {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64],
  colors: {
    background: '#FFFFFF',
    backgroundDark: '#f0e6f6',

    secondary: '#6ed4cd', // todo: change this a little bit
    // secondaryLight: '#cd5eff',
    // secondaryDark: '#fbd7ff',

    dark: '#000000',

    // oceanic theme colors
    'base 00': '#1B2B34',
    'base 01': '#343D46',
    'base 02': '#4F5B66',
    'base 03': '#65737E',
    'base 04': '#A7ADBA',
    'base 05': '#C0C5CE',
    'base 06': '#CDD3DE',
    'base 07': '#D8DEE9',
    'base 08': '#EC5f67',
    'base 09': '#F99157',
    'base 0A': '#FAC863',
    'base 0B': '#99C794',
    'base 0C': '#5FB3B3',
    'base 0D': '#6699CC',
    'base 0E': '#C594C5',
    'base 0F': '#AB7967',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  // shadows: {
  //   small: '0 0 4px rgba(0, 0, 0, .125)',
  //   large: '0 0 24px rgba(0, 0, 0, .125)',
  // },
  // variants: {
  //   link: {
  //     color: '#6ed4cd',
  //     textDecoration: 'none',
  //     borderBottom: '1px solid #6ed4cd',
  //     transition: 'color 0.5s',
  //     '&:hover': {
  //       color: '#FAC863',
  //       borderBottom: '1px solid #FAC863',
  //     },
  //   },
  // },
  text: {},
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
};
